<template>
  <transition name="slide">
    <div class="pay-video-container">
      <h :isback="true">
        <span class="name">已解锁</span>
      </h>
      <div class="main">
        <cube-scroll
          ref="scroll"
          :data="list"
          :options="options"
          @pulling-down="onPullingDown"
          @pulling-up="onPullingUp"
        >
          <preview v-show="list.length" :list="list" />
          <div v-show="list.length <= 0" class="not-data">
            <img src="./img/not-data.png" width="109" />
            <div class="txt">暂无数据</div>
          </div>
        </cube-scroll>
      </div>
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import preview from "@/components/video/preview";
import { buyVideoList } from "@/api/user";
export default {
  components: {
    h,
    preview
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 16,
      totalPages: 1,
      options: {
        click: true,
        pullDownRefresh: {
          threshold: 60,
          // stop: 44,
          stopTime: 1000,
          txt: "更新成功"
        },
        pullUpLoad: {
          threshold: 0,
          stopTime: 1000,
          txt: "数据加载成功"
        }
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };
      buyVideoList(params).then(res => {
        if (!res) {
          return;
        }
        if (this.pageNumber > 1) {
          this.list = this.list.concat(res.object.content);
        } else {
          this.list = res.object.content;
        }
        if (this.list.length) {
          for (let i = 0; i < this.list.length; i++) {
            this.list[i].horizontalImage = this.list[i].image;
            this.list[i].name = this.list[i].videoName;
          }
        }
        this.totalPages = res.object.totalPages;
      });
    },
    onPullingDown() {
      this.pageNumber = 1;
      this.getList();
    },
    onPullingUp() {
      if (this.pageNumber >= this.totalPages) {
        this.$refs.scroll.forceUpdate();
        return;
      }
      this.pageNumber++;
      this.getList();
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.pay-video-container
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #f0f0f0
  z-index 11
  .main
    height calc(100vh - 36px)
    margin-top 15px
    .not-data
      text-align center
      margin-top 120px
      .txt
        margin-top 20px
        font-size 17px
        color #CECECE
</style>
