<template>
  <ul class="video-preview">
    <router-link
      v-for="item in list"
      :key="item.id"
      tag="li"
      :to="toPlay(item)"
    >
      <div class="cover">
        <img v-lazy="resetUrl(item.horizontalImage)" class="img" />
        <span v-if="item.roleStatus == 2" class="type">VIP</span>
        <span
          v-if="item.videoType == 3"
          class="type"
          v-text="item.isPay ? '已解锁' : '金币'"
        />
        <div class="desc">
          <span v-if="item.videoType == 1" v-text="`第${item.number}集`" />
          <span v-if="item.videoType == 2" v-text="`共${item.number}集`" />
          <span v-if="item.videoType == 0 || item.videoType == 3">
            <b
              v-show="item.hour > 0"
              v-text="item.hour < 10 ? '0' + item.hour + ':' : item.hour + ':'"
            />
            <b
              v-show="item.minute > 0"
              v-text="
                item.minute < 10 ? '0' + item.minute + ':' : item.minute + ':'
              "
            />
            <b v-show="item.minute <= 0" v-text="'00:'" />
            <b v-text="item.second < 10 ? '0' + item.second : item.second" />
          </span>
        </div>
      </div>
      <div class="text ellips" v-text="item.name" />
    </router-link>
  </ul>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    ...mapState({
      token: state => state.user.token
    })
  },
  methods: {
    toPlay(item) {
      if (!this.token) {
        return "/login";
      }
      let url = "";
      if (item.videoType == 0 || item.videoType == 3) {
        url = `/video/${item.videoId}?type=${item.videoType}`;
      } else if (item.videoType == 1) {
        url = `/video/${item.categoryId}?type=${item.videoType}&number=${item.videoId}`;
      } else if (item.videoType == 2) {
        url =
          item.videoType.number <= 0
            ? `/search/more?id=${item.categoryId}`
            : `/video/${item.categoryId}?type=${item.videoType}`;
      }
      if (!item.videoType && item.isPay == 1) {
        url = `/video/${item.videoId}?type=3`;
      }
      return url;
    },
    resetUrl(url) {
      if (!url) {
        return;
      }
      return url.replace("http://qiniu", "https://qiniu");
    }
  }
};
</script>
<style lang="stylus" scoped>
.video-preview
  display flex
  flex-wrap wrap
  padding 0 15px
  li
    flex 0 0 169px
    margin 0 7px 0 0
    padding-bottom 9px
    border-radius 7px
    overflow hidden
    &:nth-of-type(2n+0)
      margin-right 0
    .cover
      position relative
      font-size 0
      .img
        height 95px
      .type
        position absolute
        top 5px
        right 6px
        width 35px
        height 14px
        line-height 14px
        background #BA9877
        border-radius 3px
        font-style normal
        font-size 10px
        color #fff
        text-align center
      .desc
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        text-align right
        box-shadow 0px -70px 6px -50px rgba(0,0,0, .6) inset
        span
          position absolute
          bottom 3px
          right 5px
          font-size 10px
          color #fff
    .text
      padding 5px 7px 7px
      background #fff
      font-size 13px
      color #383A40
</style>
